import React from "react";
import "./Step.scss";
import Bar from "../components/Bar";
import Layout from "../components/Layout";
import ImgStep0401 from "../images/step0401.jpg";
import ImgStep0402 from "../images/step0402.jpg";
import ImgStep0403 from "../images/step0403.jpg";

// Path
const step03Path = "/Step03";
const outcomePath = "/Outcome";

const Step04Page = () => {
  return (
    <Layout
      step="4"
      returnHref={step03Path}
      nextHref={outcomePath}
      returnDataColor="secondary"
      nextDataColor="primary"
      dataCheck01="done"
      dataCheck02="done"
      dataCheck03="done"
      dataCheck04="wip"
    >
      <Bar number="4" text="天板のカラーを選択してください" />

      <div className="section-lead">
        <p>部屋の壁やフローリングに合わせてお好みでお選びください。</p>
      </div>

      <div className="section-inner">
        <div className="card">
          <span className="card-title">ホワイト</span>
          <p className="card-lead">
            白に近い色の天板で、部屋に置くと優しく温かみのあるイメージを与えます。
          </p>
          <div className="card-img">
            <img src={ImgStep0401} alt="sample" />
          </div>
        </div>

        <div className="card">
          <span className="card-title">メープル</span>
          <p className="card-lead">
            年輪の現れ方によっていろいろな表情を見せる天板で、多くの部屋に合う色です。
          </p>
          <div className="card-img">
            <img src={ImgStep0402} alt="sample" />
          </div>
        </div>
        <div className="card">
          <span className="card-title">ヴィンテージ</span>
          <p className="card-lead">
            ヴィンテージ加工がされている天板で、部屋に置くとかなり存在感があります。
          </p>
          <div className="card-img">
            <img src={ImgStep0403} alt="sample" />
          </div>
        </div>
      </div>
      <div className="section-btn">
        <input type="radio" name="radio" id="radio1" />
        <label for="radio1">ホワイト</label>
        <input type="radio" name="radio" id="radio2" />
        <label for="radio2">メープル</label>
        <input type="radio" name="radio" id="radio3" />
        <label for="radio3">ヴィンテージ</label>
        <p>種類を選択し、「次へ」を押してください。</p>
      </div>
    </Layout>
  );
};

export default Step04Page;
